<template>
  <b-row v-if="loading">
    <b-col>
      <b-spinner></b-spinner>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-container class="mt-4">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <BackButton class="mr-2"></BackButton>
          <h4 class="m-0">
            New member invitation page for client
            <b-link :to="`/onboarding/client/edit/${client.id}`">{{ client.display_name }}</b-link>
          </h4>
        </div>
        <div class="col-12">
          <hr>
        </div>
      </div>
      <div class="p-3">
        <div class="row mt-3">
          <b-col>
            <div class="row">
              <div class="col-4">
                <b-form-group id="input-group-1"
                              label="* Page Title"
                              :invalid-feedback="handleError('page.title')"
                              :state="isValid('page.title')"
                              label-for="page-title-input">
                  <b-form-input id="page-title-input"
                                v-model="page.title"
                                type="text"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group id="input-group-2" label="Page URL"
                              label-for="invite-url-input">
                  <b-form-input disabled id="invite-url-input"
                                v-model="inviteUrl">
                  </b-form-input>
                </b-form-group>
              </div>
            </div>
          </b-col>
        </div>
        <div class="row mt-3">
          <b-col>
            <div class="row">
              <table class="table">
                <thead class="thead">
                <tr>
                  <th scope="col">* Target Client - Service Type</th>
                  <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="type in page.serviceTypes" :key="type.value">
                  <td class="col-md-5">
                    {{ type.text }}
                  </td>
                  <td class="align-middle">
                    <b-button @click="removeClientType(type)"
                              variant="danger">
                      <feather type="trash"></feather>
                    </b-button>
                  </td>
                </tr>
                <tr v-if="!isValid('page.service_types')">
                  <td><span class="t3 fc-red">{{ handleError('page.service_types') }}</span></td>
                </tr>
                <tr>
                  <td class="col-md-5">
                    <b-form-group id="input-client-types-label" label-for="input-client-types">
                      <b-form-select id="input-client-types"
                                     :disabled="!this.clientProvidedServiceTypes.length"
                                     v-model="page.currentSelectedServiceType"
                                     :options="this.clientProvidedServiceTypes"></b-form-select>
                    </b-form-group>
                  </td>
                  <td>
                    <b-button
                        :disabled="!page.currentSelectedServiceType || !this.clientProvidedServiceTypes.length"
                        @click="addClientType"
                        variant="success">
                      Add
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </div>
        <b-row class="mt-2" align-h="center" align-v="center">
          <b-button class="mr-2"
                    @click="cancel"
                    type="button"
                    variant="light">
            Cancel
          </b-button>
          <b-button :disabled="$v.$invalid || saving"
                    @click="saveMemberInvitation"
                    type="submit"
                    class="mr-2"
                    variant="primary">
            Save
          </b-button>
        </b-row>
      </div>
    </b-container>
  </b-row>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { required } from 'vuelidate/lib/validators';
import { parseValidationsStr } from '@sword-health/input-validation';
import BackButton from '../../../components/BackButton.vue';
import errorHandler from '../../../mixins/errorHandler';
import copyToClipboard from '../../../scripts/tools/accessibility';

export default {
  name: 'EditMemberInvitation',
  components: {
    BackButton,
  },
  mixins: [ errorHandler ],
  data() {
    return {
      saving: false,
      uuid: uuidv4(),
      clientProvidedServiceTypes: [],
      page: {
        title: '',
        serviceTypes: [],
        currentSelectedServiceType: null,
      },
    };
  },
  validations() {
    return {
      page: {
        title: parseValidationsStr({ required: true, maxL: 200 }),
        serviceTypes: { required },
      },
    };
  },
  computed: {
    loading() {
      return !this.client;
    },
    clientListOnUserEvn() {
      return this.$store.getters['Core/getClients'] || [];
    },
    client() {
      return this.clientListOnUserEvn.find(c => Number(c.id) === Number(this.$route.params.clientId));
    },
    inviteUrl() {
      return `${this.$store.getters['Onboarding/getMemberInvitationUrl']}/c/${this.client.reference}/${this.uuid}`;
    },
  },
  async beforeMount() {
    await this.$store.dispatch('Core/fetchClients');
    this.fillClientsProvidedServiceTypes();
  },
  methods: {
    fillClientsProvidedServiceTypes() {
      this.clientListOnUserEvn.forEach(client => {
        const clientConfiguration = client.configuration;
        if (!clientConfiguration || !clientConfiguration.service_types || !clientConfiguration.service_types.length) { // default
          this.clientProvidedServiceTypes.push({
            text: `${client.display_name} (ID: ${client.id}) - wellness`,
            value: `${client.id}|${client.reference}|wellness`,
          });
          return;
        }
        clientConfiguration.service_types.forEach(service => {
          this.clientProvidedServiceTypes.push({
            text: `${client.display_name} (ID: ${client.id}) - ${service}`,
            value: `${client.id}|${client.reference}|${service}`,
          });
        });
      });

      this.clientProvidedServiceTypes.sort((a, b) => a.text.localeCompare(b.text));
    },
    addClientType() {
      const selected = this.clientProvidedServiceTypes.find(item => item.value === this.page.currentSelectedServiceType);
      this.page.serviceTypes.push(selected);
      this.clientProvidedServiceTypes = this.clientProvidedServiceTypes.filter(item => item.value !== this.page.currentSelectedServiceType);
      this.page.currentSelectedServiceType = null;
    },
    removeClientType(page) {
      this.page.serviceTypes = this.page.serviceTypes.filter(item => item.value !== page.value);
      this.clientProvidedServiceTypes.push(page);
    },
    async saveMemberInvitation() {
      this.saving = true;
      const payload = {
        uuid: this.uuid,
        title: this.page.title,
        targets: this.page.serviceTypes.map(item => item.value),
      };

      const result = await this.$store.dispatch('Onboarding/createMemberInvitationPage', {
        clientRef: this.client.reference,
        payload,
      });
      if (result) {
        copyToClipboard(this.inviteUrl);
        this.$noty.success('Member invitation page created with success, url copied to clipboard');
        this.$router.push(`/onboarding/clients/${this.client.id}/invitations`);
      }
    },
    cancel() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to cancel?').then(value => {
        if (!value) {
          return;
        }
        this.$router.go(-1);
      });
    },
  },
};
</script>
